import { motion } from "framer-motion";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { MobileTaglineScream } from "../../components/TaglineScream";
import { getBgCol, getBorderCol, getGradientCol, getPrimaryCol, getTextCol } from "../../util/ColorUtil";
import Logo from "../../logo_full.png"
import { useRef } from "react";
import { Divider } from "@mantine/core";
import { MobileTeamList } from "../../components/List";
import { MobFooter } from "../../components/Footer";

export default function MobLanding()
{
    const navigate = useNavigate();
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const bgCol = getBgCol(darkMode === 'true');
    const gradientCol = getGradientCol(darkMode === 'true');

    return (
        <div className={`flex flex-col ${bgCol} pt-[100px]`}>
            <div className="flex p-4 items-center">
                <MobileTaglineScream title="Decidedly Ourselves." />
                <FloatingLogo />
            </div>
            <div className="px-6 mt-7">
                <Quote author="Christoffer Cederfeldt, CEO" quote="Here at Blindtree, we focus on making what we’d like to see; action-filled games with unique and creative presentations. Our team of developers and artists tackle exciting problems, coming up with solutions that aim to deliver the most fun experience for the player." />
            </div> 
            <div className="my-16"></div>
            <div className="flex flex-col gap-4">
                <ActionCard 
                    title="Exploring Play" 
                    text="We are dedicated to finding new, exciting ways to entertain players. By blending genres and thematical ideas, we explore how we can allow the player to be in a continuous stream of fun throughout the game.$Explore our ever-expanding library of projects to have a look for yourself!" 
                    buttonText="View Games" 
                    img={"bg-dante-globe"}
                    onAction={() => navigate("/games")}
                />
                <ActionCard 
                    title="Building Bridges" 
                    text="Blindtree is convinced that through the sharing of knowledge and experience across the industry, we can advance the medium of games to new heights.$We're always up to making new friends so don't hesitate to reach out!" 
                    img={"bg-dare-try"} 
                />
                <ActionCard 
                    title="Let's Work Together!" 
                    text="We at Blindtree are always excited to be able to help bring ideas to life. Our team of learned developers and artists stand at the ready to help make your idea a reality.$Why not send us an e-mail and let's see what happens?" 
                    img={"bg-work-together"} 
                />
            </div>
            <div className="my-16"></div>
            <MobileTeamList title="Our Team" />
            <MobFooter />
        </div>
    );
}

function FloatingLogo()
{
    return (
        <motion.div className="z-30 w-[50%] drop-shadow-max ml-auto" initial={{ opacity: 0, y: -100}} animate={{opacity: 1, y: 0}} transition={{duration: 1.3}}>
            <motion.div initial={{ scale: 1}} whileHover={{scale: 1.05}}>
                <img className="max-h-[200px] " src={Logo} alt="logo"/> 
            </motion.div>
        </motion.div>
    );
}

function Quote(props: {author: string, quote: string})
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');

    return (
        <div className={`z-30 text-[.6em] max-w-[500px] mx-auto font-quote font-bold animate-slideup ${textCol}`}>
            <div className="flex gap-4">
                <p>{">>"}</p>
                <p>{props.quote}</p>
            </div>
            <div className="font-semibold text-right mt-4">
                - {props.author}
            </div>
        </div>
    );
}

interface ActionCardProps {
    title: string
    text: string
    img: string
    buttonText?: string
    onAction?: () => void
}

function ActionCard(props: ActionCardProps)
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');
    const borderCol = getBorderCol(darkMode === 'true');
    const bgCol = getBgCol(darkMode === 'true');
    const blockArr = props.text.split('$');
    const scrollRef = useRef(null);

    return (
        <motion.div 
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
        viewport={{ root: scrollRef, once: true }}
        className={`flex flex-col jusfify-between ${textCol} z-30 ${bgCol} ${borderCol} border-[1em] w-full`}>
            <div className={`w-[300px] ${props.img} bg-cover bg-no-repeat bg-[center_-2rem] rounded-[1em] h-[150px]`}>
            </div>
            <div className="flex flex-col gap-[1em] rounded-r-[1.5em]">
                <p className="text-[1.5em] mt-[0.5em] font-bold">{props.title}</p>
                <div className="text-[0.8em] flex flex-col gap-[1em]">{blockArr.map((text, index) => {return <p key={index}>{text}</p>})}</div>
                {props.buttonText && props.onAction ? 
                <ActionButton title={props.buttonText} onClick={props.onAction} /> : <></>}
            </div>
            <Divider className="mt-6" />
        </motion.div>
    );
}

function ActionButton(props: {title: string, onClick: () => void})
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const primCol = getPrimaryCol(darkMode === 'true');

    return(
        <motion.div initial={{ scale: 1 }} whileHover={{scale: 1.05}} onClick={() => props.onClick()}
        className={`hover:cursor-pointer p-[0.8em] px-[1.2em] mt-[1em] ${primCol} w-fit rounded-[0.8em] text-white text-[0.8em] font-bold`}>
            <p><span className="underline">{props.title}</span> ➟</p>
        </motion.div>
    );
}