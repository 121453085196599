export function Tagline(props: {title: string, classname?: string})
{
    return (
        <p className={`font-bold text-[5em] lg:text-[5em] xl:text-[5em] 2xl:text-[7em] text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-500 drop-shadow-max leading-tight animate-slideright ${props.classname}`}>
            {props.title}
        </p>
    );
}

export function MobileTaglineScream(props: {title: string})
{
    return (
        <div>
            <p className='z-30 pb-2 font-bold text-[2em] text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-500 drop-shadow-2xl leading-tight animate-slideright'>
                {props.title}
            </p>
        </div>
    );
}