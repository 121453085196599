import { Switch } from "@mantine/core";
import { useCookies } from "react-cookie";
import SmallLogo from "../logo_small.svg"
import { getPrimaryCol, getTextCol } from "../util/ColorUtil";

export function NeoFooter() {
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');
    const primCol = getPrimaryCol(darkMode === 'true');

    return (
        <div className={`sticky mt-auto z-10 w-full h-[200px] ${primCol} text-[1em] ${textCol}`}>
            <div className="p-[2em] lg:w-[900px] xl:w-[1000px] 2xl:w-[1250px] mx-auto flex">
                <img src={SmallLogo} alt="logo" className="h-[4em] invert" />
                <div className="border-l-2 border-black pl-[1em] pr-[2em]">
                    <p className="opacity-[75%]">Contact</p>
                    <a className="underline font-semibold hover:cursor-pointer" href="mailto:contact@blindtree.se"><p>contact@blindtree.se</p></a>
                </div>
                <div className="border-l-2 border-black pl-[1em] pr-[2em]">
                    <p className="opacity-[75%]">Social Media</p>
                    <a className="underline font-semibold hover:cursor-pointer" href="https://twitter.com/BlindtreeStudio"><p>Twitter</p></a>
                    <a className="underline font-semibold hover:cursor-pointer" href="https://www.tiktok.com/@blindtreestudios"><p>TikTok</p></a>
                    <a className="underline font-semibold hover:cursor-pointer" href="https://blindtree.itch.io/"><p>itch.io</p></a>
                </div>
            </div>
        </div>
    );
}


export function MobFooter() {
    const [{"dark-mode": darkMode}, setCookies] = useCookies(['dark-mode']);
    const state = darkMode === 'true'
    const textCol = getTextCol(darkMode === 'true');
    const primCol = getPrimaryCol(darkMode === 'true');

    return (
        <div className={`z-10 w-full h-[160px] ${primCol} text-[.8em]`}>
            <div className="p-4 mx-auto flex h-full">
                <img src={SmallLogo} alt="logo" className="h-[4em] invert" />
                <div className="border-l-2 border-black flex flex-col w-full">
                    <div className="p-2">
                        <p className="opacity-[75%]">Contact</p>
                        <a className="underline font-semibold hover:cursor-pointer" href="mailto:contact@blindtree.se"><p>contact@blindtree.se</p></a>
                    </div>
                    <div className="p-2">
                        <p className="opacity-[75%]">Social Media</p>
                        <div className="flex gap-4">
                            <a className="underline font-semibold hover:cursor-pointer" href="https://twitter.com/BlindtreeStudio"><p>Twitter</p></a>
                            <a className="underline font-semibold hover:cursor-pointer" href="https://www.tiktok.com/@blindtreestudios"><p>TikTok</p></a>
                            <a className="underline font-semibold hover:cursor-pointer" href="https://blindtree.itch.io/"><p>itch.io</p></a>
                        </div>
                    </div>
                </div>
                <div>
                    <Switch
                        size="sm"
                        color={"red"} 
                        checked={state} 
                        onChange={(event) => setCookies("dark-mode", event.currentTarget.checked)} 
                        onLabel={<p className="text-[1.5em]">{"🌙"}</p>}
                        offLabel={<p className="text-[1.5em]">{"☀️"}</p>}
                    />
                </div>
            </div>
        </div>
    );
}