import { motion } from "framer-motion";
import { useCookies } from "react-cookie";
import { Navigate, useParams } from "react-router-dom";
import { NeoFooter } from "../components/Footer";
import { List } from "../components/List";
import data from '../data/gamePages.json';
import { getBgCol } from "../util/ColorUtil";
const { pages } = data;

export default function GameSummary() {
    const { gameId } = useParams();
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const bgCol = getBgCol(darkMode === 'true');

    let pageData = pages.find(page => {return page.key === gameId});

    if (!pageData) return <Navigate to="/games" />;
    const blockArr = pageData.pageContent.split('$');

    const itchIntegration = pageData.platforms.find((item) => {return item.key === 'itch'});

    return (pageData ? <div>
        {pageData.embedHTML ? 
        <div className="bg-[#2d2e30] p-12 mt-[60px] z-30 [&_*]:z-30 flex justify-center" dangerouslySetInnerHTML={{__html: pageData.embedHTML}}>
        </div> : <div className={`h-[10vh] ${bgCol}`}></div>}
        <div className="flex flex-col min-h-[90vh] mt-[5vh]">
            <div className="grid lg:grid-cols-3 grid-cols-1 w-[700px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1250px] mb-[150px] gap-y-[100px] mx-auto">
                <motion.div className="z-20 col-span-2"
                    initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}}
                    transition={{duration: 1}}>
                    <List title={pageData.title} titleSize="text-[3em]" >
                        <div className="flex flex-col text-[1em] ml-[2em] gap-[1em]">
                            {blockArr.map((text, index) => {return <p key={index} dangerouslySetInnerHTML={{__html: text}}></p>})}
                        </div>
                    </List>
                </motion.div>
                <motion.div 
                initial={{x: 100, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 1.1}}
                className="z-30 mt-[1em] flex flex-col gap-[2em]">
                    <div className="border rounded-[1vh] bg-white shadow-lg p-[2vh] h-fit flex flex-col gap-[1vh]">
                        <div className="text-[1em]">
                            <p className="font-bold">Created by:</p>
                            <p>{pageData.credits}</p>
                        </div>
                        <div className="flex">
                            {itchIntegration ? <ItchButton href={itchIntegration.link} /> : <></>}
                        </div>
                    </div>
                    <div className="border rounded-xl bg-white shadow-xl p-[1em] grid grid-cols-2 lg:grid-cols-1 gap-[1em]">
                        {pageData.images.map((url, index) => {return <img src={url} key={index} className=" mx-auto" />})}
                    </div>
                </motion.div>
            </div>
            <NeoFooter />
        </div>
    </div>: <></>);
}

function ItchButton(props: {href?: string})
{
    return (
        <motion.div 
        initial={{scale: 1}} whileHover={{scale: 1.05}}
        onClick={() => {window.open(props.href, "_blank")}}
        className="rounded-xl bg-[#fa5c5c] font-bold text-[1em] p-[1em] text-white flex items-center gap-[1em] hover:cursor-pointer">
            <img src="https://static.itch.io/images/itchio-textless-white.svg" className="h-[1.5em]" />
            View on itch.io
        </motion.div>
    );
}