import { motion } from "framer-motion";
import { useRef } from "react";
import { useCookies } from "react-cookie";
import { getBorderCol, getPrimaryBorderCol, getPrimaryGradientCol, getTextCol } from "../util/ColorUtil";

export function TeamList(props: {title: string}) {
    return (
        <List title={props.title}>
            <TeamItem name="Christoffer Cederfeldt" roles="CEO, Programmer"
            profileImg="bg-chr-ced" quote="As the CEO my priority is and will always be the team and our shared creative vision. And as a programmer I find my passion in game development, design, and interesting challenges." />
                    
            <TeamItem name="Sebastian Alkstrand" roles="Programmer, UI Designer, Web Developer, Writer"
            profileImg="bg-seb-alk" quote="I’m someone always excited to see how far Gameplay, Design and Writing can be combined.
            Many games nowadays tend to sacrifice moment-to-moment gameplay for more cinematic storytelling methods, but is there a way to keep the player in the moment while telling large narratives? That’s what I’m excited to explore." />
            
            <TeamItem name="Hirad Salmend" roles="Game Designer, PR Manager"
            profileImg="bg-hir-sal" quote="My focus as a developer has always been to make sure that moment-to-moment gameplay pulls players in and immerses the player in the game. Creating enjoyable experiences where gameplay and player expression can shine through and be shared among others." />
            
            <TeamItem name="Albin Dahlheim" roles="Artist, Game Designer, Programmer"
            profileImg="bg-alb-dah" quote="To me the most important part of creating games is making sure they are enjoyable to play.
            Seeing someone genuinely have fun with something you helped create is one of the best feelings
            in the world, and it’s the reason I love making games." />
            
            <TeamItem name="Benjamin Larsson" roles="Programmer, Technical Artist, Web Developer"
            profileImg="bg-ben-lar" quote="I love the way that game development lets me combine technical challenges and artistic expression to craft a world that brings joy to those who choose to inhabit it." />
            
            <TeamItem name="Jonathan Ormark Hallin" roles="Sound Designer, Composer, Game Designer"
            profileImg="bg-jon-orm" quote="I love when games really pull you in and you lose yourself for a moment. The visuals, sounds, lighting and music immerses you into a world to the point that you feel like you're a part of. This is something not many games have accomplished for me, but it's something I strive for when making games." />
        </List>
    );
}

export function MobileTeamList(props: {title: string}) {
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');

    return (
        <div className={`flex flex-col p-4 ${textCol}`}>
            <p className="text-[3em] font-bold  py-4">{props.title}</p>
            <MobileTeamItem name="Christoffer Cederfeldt" roles="CEO, Programmer"
            profileImg="bg-chr-ced" quote="As the CEO my priority is and will always be the team and our shared creative vision. And as a programmer I find my passion in game development, design, and interesting challenges." />
                    
            <MobileTeamItem name="Sebastian Alkstrand" roles="Programmer, UI Designer, Web Developer, Writer"
            profileImg="bg-seb-alk" quote="I’m someone always excited to see how far Gameplay, Design and Writing can be combined.
            Many games nowadays tend to sacrifice moment-to-moment gameplay for more cinematic storytelling methods, but is there a way to keep the player in the moment while telling large narratives? That’s what I’m excited to explore." />
            
            <MobileTeamItem name="Hirad Salmend" roles="Game Designer, PR Manager"
            profileImg="bg-hir-sal" quote="My focus as a developer has always been to make sure that moment-to-moment gameplay pulls players in and immerses the player in the game. Creating enjoyable experiences where gameplay and player expression can shine through and be shared among others." />
            
            <MobileTeamItem name="Albin Dahlheim" roles="Artist, Game Designer, Programmer"
            profileImg="bg-alb-dah" quote="To me the most important part of creating games is making sure they are enjoyable to play.
            Seeing someone genuinely have fun with something you helped create is one of the best feelings
            in the world, and it’s the reason I love making games." />
            
            <MobileTeamItem name="Benjamin Larsson" roles="Programmer, Technical Artist, Web Developer"
            profileImg="bg-ben-lar" quote="I love the way that game development lets me combine technical challenges and artistic expression to craft a world that brings joy to those who choose to inhabit it." />
            
            <MobileTeamItem name="Jonathan Ormark Hallin" roles="Sound Designer, Composer, Game Designer"
            profileImg="bg-jon-orm" quote="I love when games really pull you in and you lose yourself for a moment. The visuals, sounds, lighting and music immerses you into a world to the point that you feel like you're a part of. This is something not many games have accomplished for me, but it's something I strive for when making games." />
        </div>
    );
}

interface TeamItemProps {
    profileImg: string
    name: string
    roles: string
    quote: string
}

function TeamItem(props: TeamItemProps) {
    const scrollRef = useRef(null);
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const borderCol = getBorderCol(darkMode === 'true');
    const pBorderCol = getPrimaryBorderCol(darkMode === 'true');

    return (
        <motion.div
        initial={{opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ root: scrollRef, once: true }} 
        className="flex gap-[1em]">
            <motion.div initial={{ borderWidth: '.4em'}}
            whileHover={{borderWidth: "0.01em"}}
            className={`${pBorderCol} min-w-[8em] h-[8em]`}>
                <div className={`w-full h-full ${props.profileImg} bg-cover ${borderCol} border-[.4em]`}></div>
            </motion.div>
            <div className="flex flex-col">
                <p className="text-[2em] font-bold">{props.name}</p>
                <p className="text-[1em] mt-[-1vh]">{props.roles}</p>
                <p className="p-[1em] text-[1em] italic">"{props.quote}"</p>
            </div>
        </motion.div>
    );
}

function MobileTeamItem(props: TeamItemProps) {
    const scrollRef = useRef(null);
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const borderCol = getBorderCol(darkMode === 'true');
    const pBorderCol = getPrimaryBorderCol(darkMode === 'true');

    return (
        <motion.div
        initial={{opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ root: scrollRef, once: true }} 
        className="flex flex-col mb-12">
            <div className="flex gap-4 items-end">
                <motion.div initial={{ borderWidth: '.3em'}}
                whileHover={{borderWidth: ".01em"}}
                className={`${pBorderCol} w-[5em] h-[5em]`}><div className={`w-full h-full ${props.profileImg} bg-cover ${borderCol} border-[.3vh]`}></div></motion.div>
                <div>
                <p className="text-[1.2em] font-bold">{props.name}</p>
                <p className="text-[0.8em] w-[200px] mt-[-.5em]">{props.roles}</p>
                </div>
            </div>
            <div className="flex flex-col">
                <p className="p-[2vh] text-[.8em] italic">"{props.quote}"</p>
            </div>
        </motion.div>
    );
}

export function List(props: {title: string, titleSize?: string, children: React.ReactNode}) {
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');
    const pGradientCol = getPrimaryGradientCol(darkMode === 'true');
    
    return (
        <div className={`z-30 relative h-full ${textCol}`}>
            <p className={`${props.titleSize ?? "text-[5em]"} font-bold`}>{props.title}</p>
            <div className="flex h-full">
                <div className={`bg-gradient-to-b ${pGradientCol} min-w-[10px] max-w-[10px]`} />
                <div className="p-[2em] pb-0 text-[1em] w-full flex flex-col gap-[50px]">
                    {props.children}
                </div>
            </div>
        </div>
    );
}