import { motion } from "framer-motion";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { MobFooter } from "../../components/Footer";
import { getBgCol, getPrimaryBorderCol, getTextCol } from "../../util/ColorUtil";

export default function MobGames()
{
    const navigate = useNavigate();
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const bgCol = getBgCol(darkMode === 'true');
    const textCol = getTextCol(darkMode === 'true');
    
    return(
        <>
            <div className="pt-[80px]">
                <div className={`flex flex-col gap-4 p-4 ${textCol}`}>
                    <p className="text-[2em] font-bold py-4">Our Games</p>
                    <MobGameSquare title="Marshmallow Ninjas" id="marsmallow_ninjas" img="bg-marshmallow-ninjas" onAction={navigate} />
                    <MobGameSquare title="Orderless: New Skies" id="orderless" img="bg-orderless" onAction={navigate} />
                </div>
                <div className={`flex flex-col gap-4 p-4 pb-[80px] ${textCol}`}>
                    <p className="text-[2em] font-bold py-4">Other Projects by us</p>
                    <MobGameSquare title="Blue Parasite" id="blueparasite" credits="Sebastian Alkstrand" img="bg-parasite" onAction={navigate} />
                    <MobGameSquare title="Adiasis" id="adiasis" credits="Albin Dahlheim" img="bg-adiasis" onAction={navigate} />
                    <MobGameSquare title="Nalleland" id="nalleland" credits="Benjamin Larsson" img="bg-nalleland" onAction={navigate} />
                    <MobGameSquare title="A Rogue Pawn" id="roguepawn" credits="Sebastian Alkstrand" img="bg-rogue_pawn" onAction={navigate} />
                    <MobGameSquare title="Frachenstein’s Revenge!" id="frachenstein" credits="Christoffer Cederfeldt, Hirad Salmend, Benjamin Larsson, Albin Dahlheim" img="bg-frachenstein" onAction={navigate} />
                    <MobGameSquare title="Nutdealer" id="nutdealer" credits="Christoffer Cederfeldt" img="bg-nutdealer" onAction={navigate} />
                    <MobGameSquare title="On the Road" id="ontheroad" credits="Albin Dahlheim" img="bg-road" onAction={navigate} />
                    <MobGameSquare title="Huggernaut" id="huggernaut" credits="Benjamin Larsson" img="bg-huggernaut" onAction={navigate} />
                </div>
                <MobFooter />
            </div>
        </>
    );
}

function MobGameSquare(props: {title: string, img: string, id: string, credits?: string, onAction?: (path: string) => void})
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const pBorderCol = getPrimaryBorderCol(darkMode === 'true');

    return (
        <motion.div
        initial={{opacity: 0, x: -100}} 
        whileInView={{opacity: 1, x: 0}}
        viewport={{once: true}}
        className="text-[.8em] hover:cursor-pointer" onClick={() => {if (props.onAction) props.onAction(`${props.id}`)}}>
            <p className="mx-4 text-[1.5em] font-bold">{props.title}</p>
            <motion.div 
            initial={{borderWidth: '1em'}}
            whileHover={{borderWidth: '0.01em'}} 
            className={`rounded-[1em] ${pBorderCol} border-[.8em] w-full h-[220px] ${props.img} bg-cover bg-center`}>
            </motion.div>
            {props.credits ? <p className="ml-[2vh] max-w-[40vh] text-[#626262] font-semibold">by: {props.credits}</p> : <></>}
        </motion.div>
    );
}