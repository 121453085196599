import { motion } from "framer-motion";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { NeoFooter } from "../components/Footer";
import { List } from "../components/List";
import { getBgCol, getPrimaryBorderCol, getTextCol } from "../util/ColorUtil";

export default function Games()
{
    const navigate = useNavigate();
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const bgCol = getBgCol(darkMode === 'true');
    const textCol = getTextCol(darkMode === 'true');

    return(
        <div className={`flex flex-col min-h-[90vh] mt-[15vh] font-inter ${textCol}`}>
            <div className="flex flex-col lg:w-[900px] xl:w-[1000px] 2xl:w-[1250px] mx-auto mb-[150px] gap-[150px]">
                <List title="Our Games">
                    <div className="grid grid-cols-1 2xl:grid-cols-2 gap-y-[50px] w-full">
                        <GameSquare title="Marshmallow Ninjas" id="marsmallow_ninjas" img="bg-marshmallow-ninjas" onAction={navigate} />
                        <GameSquare title="Orderless: New Skies" id="orderless" img="bg-orderless" onAction={navigate} />
                    </div>
                </List>
                <List title="Other Projects by us" >
                <div className="grid grid-cols-1 2xl:grid-cols-2 gap-y-[50px] w-full">
                    <GameSquare title="Blue Parasite" id="blueparasite" credits="Sebastian Alkstrand" img="bg-parasite" onAction={navigate} />
                    <GameSquare title="Adiasis" id="adiasis" credits="Albin Dahlheim" img="bg-adiasis" onAction={navigate} />
                    <GameSquare title="Nalleland" id="nalleland" credits="Benjamin Larsson" img="bg-nalleland" onAction={navigate} />
                    <GameSquare title="A Rogue Pawn" id="roguepawn" credits="Sebastian Alkstrand" img="bg-rogue_pawn" onAction={navigate} />
                    <GameSquare title="Frachenstein’s Revenge!" id="frachenstein" credits="Christoffer Cederfeldt, Hirad Salmend, Benjamin Larsson, Albin Dahlheim" img="bg-frachenstein" onAction={navigate} />
                    <GameSquare title="Nutdealer" id="nutdealer" credits="Christoffer Cederfeldt" img="bg-nutdealer" onAction={navigate} />
                    <GameSquare title="On the Road" id="ontheroad" credits="Albin Dahlheim" img="bg-road" onAction={navigate} />
                    <GameSquare title="Huggernaut" id="huggernaut" credits="Benjamin Larsson" img="bg-huggernaut" onAction={navigate} />
                </div>
            </List>
            </div>
            <div className={`fixed top-0 bottom-0 left-0 right-0 ${bgCol}`} />
            <NeoFooter />
        </div>
    );
}

function GameSquare(props: {title: string, img: string, id: string, credits?: string, onAction?: (path: string) => void})
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const pBorderCol = getPrimaryBorderCol(darkMode === 'true');

    return (
        <div className="col-span-1 2xl:mx-auto">
            <motion.div
            initial={{opacity: 0, x: -100}} 
            whileInView={{opacity: 1, x: 0}}
            viewport={{once: true}}
            className="hover:cursor-pointer hidden 2xl:block" onClick={() => {if (props.onAction) props.onAction(`${props.id}`)}}>
                <p className="ml-[1em] text-[1.5em] font-bold">{props.title}</p>
                <motion.div 
                initial={{borderWidth: '.8em'}}
                whileHover={{borderWidth: '0.01em'}} 
                className={`rounded-2xl ${pBorderCol} border-[.8em] h-[240px] w-[429px] ${props.img} bg-cover bg-center`}>
                </motion.div>
                {props.credits ? <p className="ml-[2em] max-w-[400px] text-[#626262] font-semibold">by: {props.credits}</p> : <></>}
            </motion.div>
            <InlineGameSquare title={props.title} img={props.img} id={props.id} credits={props.credits} onAction={props.onAction}  />
        </div>
    );
}

function InlineGameSquare(props: {title: string, img: string, id: string, credits?: string, onAction?: (path: string) => void})
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const pBorderCol = getPrimaryBorderCol(darkMode === 'true');

    return (
        <motion.div
        initial={{opacity: 0, x: -100}} 
        whileInView={{opacity: 1, x: 0}}
        viewport={{once: true}}
        className="hover:cursor-pointer col-span-1 flex 2xl:hidden" onClick={() => {if (props.onAction) props.onAction(`${props.id}`)}}>
            <motion.div 
            initial={{borderWidth: '.8em'}}
            whileHover={{borderWidth: '0.01em'}} 
            className={`rounded-2xl ${pBorderCol} border-[.8em] h-[240px] min-w-[429px] max-w-[429px] ${props.img} bg-cover bg-center`}>
            </motion.div>
            <div>
                <p className="ml-[1em] text-[1.5em] font-bold">{props.title}</p>
                {props.credits ? <p className="ml-[2em] w-auto text-[#626262] font-semibold">by: {props.credits}</p> : <></>}
            </div>
        </motion.div>
    );
}