import { MobileNavbar, Navbar } from "./components/Navbar";
import Landing from "./pages/Landing";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Consulting from "./pages/Consulting";
import Games from "./pages/Games";
import ScrollToTop from "./hooks/ScrollToTop";
import GameSummary from "./pages/GameSummary";
import { useCookies } from "react-cookie";
import { getBgCol } from "./util/ColorUtil";
import {BrowserView, MobileView} from 'react-device-detect';
import MobLanding from "./pages/mobile/Ladning";
import MobConsulting from "./pages/mobile/Consulting";
import MobGames from "./pages/mobile/Games";
import MobGameSummary from "./pages/mobile/GameSummary";

function App() {
    const [{"dark-mode": darkMode}, setCookie] = useCookies(['dark-mode']);
    const bgCol = getBgCol(darkMode === 'true');

    if (document.cookie.indexOf('dark-mode') < 0) setCookie("dark-mode", false);

    return <div className={bgCol}>
        <BrowserView>
            <BrowserRouter>
                    <ScrollToTop />
                    <Navbar />
                <Routes>
                    <Route path="/" element={<Landing/>} />
                    {/* <Route path="/consulting" element={<Consulting/>} /> */}
                    <Route path="/games" element={<Games/>} />
                    <Route path="/games/:gameId" element={<GameSummary/>} />
                </Routes>
            </BrowserRouter>
        </BrowserView>
        <MobileView>
            <BrowserRouter>
                    <ScrollToTop />
                    <MobileNavbar />
                <Routes>
                    <Route path="/" element={<MobLanding/>} />
                    <Route path="/consulting" element={<MobConsulting/>} />
                    <Route path="/games" element={<MobGames/>} />
                    <Route path="/games/:gameId" element={<MobGameSummary/>} />
                </Routes>
            </BrowserRouter>
        </MobileView>
    </div>;
}

    
export default App;