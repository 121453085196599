import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { MobFooter } from "../../components/Footer";
import { MobileTaglineScream } from "../../components/TaglineScream";
import Email from "../../email.svg"
import Team from "../../personel/team_1.png"
import { getPrimaryBorderCol, getTextCol } from "../../util/ColorUtil";

export default function MobConsulting() {
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');

    return (
        <div className="pt-[150px]">
            <div className={`${textCol} relative flex flex-col font-inter px-4 pb-[150px] min-h-[85nvh] mx-auto`}>
                <MobileTaglineScream title='How can we help?' />
                <img src={Team} className="rounded-full rounded-tl-none animate-slideleft" />
                <ServicesList />
                <ConsultContact />
            </div>
            <MobFooter />
        </div>
    );
}

const services = ["Unity Engine", "Web Development", "UI Design", "Game Development"]

function ServicesList()
{
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index + 1) % services.length)
          }, 2000)
      
        return (() => clearInterval(interval)) //This is a cleanup function
    })

    return (
        <div className="text-[1em] mx-4 mt-8 text-right">
            <p>Our team has experience with</p>
            <motion.div
            key={index} 
            initial={{opacity: 0, y: '3vh'}}
            animate={{opacity: 1, y: 0}}
            className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-500 leading-tight font-bold text-[1.8em]">
                {services[index].toUpperCase()}
            </motion.div>
        </div>
    );
}

function ConsultContact() {
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const pBorderCol = getPrimaryBorderCol(darkMode === 'true');

    return (
        <div className="text-[1em] pt-6 mx-4">
            <p className="ml-[3vh]">Reach out to us:</p>
            <div className={`p-[1em] ${pBorderCol} border-[.4em] rounded-[1em]`}>
                <div className="flex items-center gap-[1em]">
                    <img src={Email} alt="email icon" className={`w-[2.5em] ${darkMode === 'true' ? 'invert' : ''}`} /> 
                    <a className="underline hover:cursor-pointer" href="mailto:consulting@blindtree.se">consulting@blindtree.se</a>
                </div>
            </div>
        </div>
    );
}