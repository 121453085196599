import { motion } from "framer-motion"
import Logo from "../logo_full.png"
import Trail from "../expanded_trail.svg"
import TrailDark from "../expanded_trail_dark.svg"
import ArrowDown from "../arrow_down.svg"
import { useRef } from "react";
import { TeamList } from "../components/List";
import { NeoFooter } from "../components/Footer";
import { Tagline } from "../components/TaglineScream";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getBgCol, getBorderCol, getGradientCol, getLightPrimaryCol, getPrimaryCol, getTextCol } from "../util/ColorUtil";

export default function Landing()
{
    const navigate = useNavigate();
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const bgCol = getBgCol(darkMode === 'true');
    const bgPrimCol = getLightPrimaryCol(darkMode === 'true');
    const gradientCol = getGradientCol(darkMode === 'true');

    return (<div className={`${bgCol}`}>
        <TrailFull />
        <div className="flex flex-col min-h-[90vh] mt-[20vh] font-inter">
            <div className="flex flex-col lg:w-[900px] xl:w-[1000px] 2xl:w-[1250px] mx-auto mb-[200px]">
            <div className="grid grid-cols-3 z-40 rounded-lg gap-4 border p-12 shadow-xl">
                <div className="col-span-2 flex flex-col gap-[20px]">
                    <Tagline title='Decidedly Ourselves.' />
                    <Quote author="Christoffer Cederfeldt, CEO" quote="Here at Blindtree, we focus on making what we’d like to see; action-filled games with unique and creative presentations. Our team of developers and artists tackle exciting problems, coming up with solutions that aim to deliver the most fun experience for the player." />
                </div>
                <div className={`col-span-1 ${bgPrimCol} flex items-center rounded-lg shadow-md`}>
                    <GridLogo />
                </div>
            </div>
            
            <img src={ArrowDown} alt="scroll down" className="z-30 mx-auto mb-[100px] mt-[.8em] xl:mt-[100px] 2xl:mt-[100px]" />
            
            <div className="flex flex-col gap-[100px] w-full mx-auto">
                <ActionCard 
                    title="Exploring Play" 
                    text="We are dedicated to finding new, exciting ways to entertain players. By blending genres and thematical ideas, we explore how we can allow the player to be in a continuous stream of fun throughout the game.$Explore our ever-expanding library of projects to have a look for yourself!" 
                    buttonText="View Games" 
                    img={"bg-dante-globe"} 
                    side="ml-auto"  xOffset={100}
                    onAction={() => navigate("/games")}
                />
                <ActionCard 
                    title="Building Bridges" 
                    text="Blindtree is convinced that through the sharing of knowledge and experience across the industry, we can advance the medium of games to new heights.$We're always up to making new friends so don't hesitate to reach out!" 
                    img={"bg-dare-try"} 
                    side="mr-auto"  xOffset={-100}
                />
                <ActionCard 
                    title="Let's Work Together!" 
                    text="We at Blindtree are always excited to be able to help bring ideas to life. Our team of learned developers and artists stand at the ready to help make your idea a reality.$Why not send us an e-mail and let's see what happens?" 
                    img={"bg-work-together"} 
                    side="ml-auto" xOffset={100}
                /> 
            </div>
            <div>
                <div className={`h-[400px] bg-gradient-to-t ${gradientCol}`} />
                <div className={`${bgCol} relative w-full`}>
                    <TeamList title="Our Team" />
                </div>
            </div>
            </div>
            <NeoFooter />
        </div>
        <div className={`fixed top-0 bottom-0 left-0 right-0 ${bgCol}`} />
        </div>
        );
}

function GridLogo()
{
    return (
        <motion.div className="" initial={{ opacity: 0, y: -100}} animate={{opacity: 1, y: 0}} transition={{duration: 1.3}}>
            <motion.div initial={{ scale: 1.2}} whileHover={{scale: 1.25}}>
                <img src={Logo} alt="logo" 
                /> 
            </motion.div>
        </motion.div>
    );
}

function TrailFull()
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const bgCol = getBgCol(darkMode === 'true');

    return (
        <img src={darkMode === 'true' ? TrailDark : Trail} alt="logo" 
        className={`overflow-hidden absolute z-20 top-[860px] lg:w-[1200px] xl:w-[1300px] 2xl:w-[3000px] h-auto left-[50%] translate-x-[-50%] select-none ${bgCol}`}
        />
    );
}

function Quote(props: {author: string, quote: string})
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');

    return (
        <div className={`ml-[-28px] lg:text-[0.7em] xl:text-[0.8em] 2xl:text-[1em] p-8 font-quote animate-slideup ${textCol}`}>
            <div className="flex gap-4">
                <p className="font-bold">{">>"}</p>
                <p>{props.quote}</p>
            </div>
            <div className="font-semibold text-right mt-4">
                - {props.author}
            </div>
        </div>
    );
}

interface ActionCardProps {
    title: string
    text: string
    img: string
    top?: string
    side: string
    xOffset: number
    buttonText?: string
    onAction?: () => void
}

function ActionCard(props: ActionCardProps)
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');
    const borderCol = getBorderCol(darkMode === 'true');
    const bgCol = getBgCol(darkMode === 'true');
    const blockArr = props.text.split('$');
    const scrollRef = useRef(null);

    return (
        <motion.div 
        initial={{ opacity: 0, x: props.xOffset }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1.2 }}
        viewport={{ root: scrollRef, once: true }}
        className={`${props.side} ${textCol} z-30 rounded-2xl ${bgCol} ${borderCol} border-[1em] h-[300px] w-[600px] 2xl:h-[450px] 2xl:w-[800px] drop-shadow-max`}>
            <div className={`absolute right-[50%] left-0 bottom-0 top-0 rounded-l-xl ${props.img} bg-cover bg-no-repeat bg-center`}>
            </div>
            <div className="absolute flex flex-col gap-[1em] left-[50%] right-0 bottom-0 top-0 rounded-r-xl px-[1em]">
                <p className="2xl:text-[2em] text-[1.2em] mt-[0.5em] font-bold">{props.title}</p>
                <div className="2xl:text-[1em] text-[0.8em] flex flex-col gap-[1em]">{blockArr.map((text, index) => {return <p key={index}>{text}</p>})}</div>
                {props.buttonText && props.onAction ? 
                <ActionButton title={props.buttonText} onClick={props.onAction} /> : <></>}
            </div>
        </motion.div>
    );
}

function ActionButton(props: {title: string, onClick: () => void})
{
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const primCol = getPrimaryCol(darkMode === 'true');

    return(
        <motion.div initial={{ scale: 1 }} whileHover={{scale: 1.05}} onClick={() => props.onClick()}
        className={`hover:cursor-pointer absolute right-2 bottom-2 p-2 px-4 ${primCol} w-fit rounded-lg text-white text-[.8em] font-semibold`}>
            <p><span className="underline">{props.title}</span> ➟</p>
        </motion.div>
    );
}