import { useCookies } from "react-cookie";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { MobFooter } from "../../components/Footer";
import data from '../../data/gamePages.json';
import { getPrimaryCol, getTextCol } from "../../util/ColorUtil";
const { pages } = data;

export default function MobGameSummary() {
    const navigate = useNavigate();
    const { gameId } = useParams();
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const col = getPrimaryCol(darkMode === 'true');
    const textCol = getTextCol(darkMode === 'true');

    let pageData = pages.find(page => {return page.key === gameId});

    if (!pageData) return <Navigate to="/games" />;
    const blockArr = pageData.pageContent.split('$');

    const itchIntegration = pageData.platforms.find((item) => {return item.key === 'itch'});

    return (pageData ? <>
        <div className="mx-4 py-[80px]" >
            <div className={`z-20 px-4 py-2 mb-8 text-white w-fit rounded-[.5em] ${col}`} onClick={() => {navigate("/games")}}>Back to Games</div>
            <p className={`text-[2em] font-bold ${textCol}`}>{pageData.title}</p>
            <p className={`text-[.8em] ${textCol}`}>by: <span className="font-semibold">{pageData.credits}</span></p>
            <div className="flex flex-col">
                {pageData.images.map((url, index) => {return <img src={url} key={index} className="w-full pt-4 mx-auto" />})}
            </div>
            <div className={`mt-8 flex flex-col gap-4 ${textCol}`}>
                {blockArr.map((text, index) => {return <p key={index} dangerouslySetInnerHTML={{__html: text}}></p>})}
                {itchIntegration ? 
                <div className={`px-4 py-2 mb-8 text-white bg-[#fa5c5c] w-fit flex gap-3 items-center rounded-[.5em]`} onClick={() => {window.open(itchIntegration.link, "_blank")}}>
                    <img src="https://static.itch.io/images/itchio-textless-white.svg" className="h-[1.2em]"></img>
                    View on itch.io
                </div> : <></>}
            </div>
        </div>
        <MobFooter />
    </>: <></>);
}