export function getTextCol(darkMode: boolean) {
    return darkMode ? 'text-white' : 'text-black';
}

export function getInvTextCol(darkMode: boolean) {
    return darkMode ? 'text-black' : 'text-white';
}

export function getGradientCol(darkMode: boolean) {
    return darkMode ? 'from-[#161616]' : 'from-white';
}

export function getBgCol(darkMode: boolean) {
    return darkMode ? 'bg-[#161616]' : 'bg-white';
}

export function getBorderCol(darkMode: boolean) {
    return darkMode ? 'border-[#161616]' : 'border-white';
}

export function getPrimaryCol(darkMode: boolean) {
    return darkMode ? 'bg-[#A42828]' : "bg-[#47C2A5]";
}

export function getLightPrimaryCol(darkMode: boolean) {
    return darkMode ? 'bg-[#2d2e30]' : "bg-[#2d2e30]";
}

export function getPrimaryBorderCol(darkMode: boolean) {
    return darkMode ? 'border-[#A42828]' : "border-[#47C2A5]";
}

export function getPrimaryGradientCol(darkMode: boolean) {
    return darkMode ? 'from-[#A42828]' : 'from-[#47C2A5]';
}
export function getCol(darkMode: boolean) {
    return darkMode ? '#A42828' : '#47C2A5';
}