import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import SmallLogo from "../logo_small.svg"
import { getBgCol, getTextCol } from "../util/ColorUtil";
import { Switch } from '@mantine/core';

export function Navbar()
{
    const navigate = useNavigate();
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');
    const bgCol = getBgCol(darkMode === 'true');

    return (
        <div className={`z-50 fixed flex top-0 w-[100vw] h-[60px] ${bgCol} shadow-2xl ${textCol}`}>
            <div className="flex p-4 justify-around w-full">
                <NavbarTitle onClick={() => navigate("/")} invert={darkMode === 'true'} />
                <NavbarItems onClick={(path: string) => navigate(path)}/>
            </div>
        </div>
    );
}

export function MobileNavbar()
{
    const navigate = useNavigate();
    const [{"dark-mode": darkMode}] = useCookies(['dark-mode']);
    const textCol = getTextCol(darkMode === 'true');
    const bgCol = getBgCol(darkMode === 'true');

    return (
        <div className={`z-50 fixed flex top-0 w-[100vw] h-[60px] ${bgCol} shadow-2xl ${textCol}`}>
            <div className="flex p-4 justify-between w-full">
                <NavbarTitle onClick={() => navigate("/")} invert={darkMode === 'true'} />
            </div>
        </div>
    );
}

function NavbarTitle(props: {onClick: () => void, invert: boolean})
{
    return (
        <div className="flex items-center gap-[.5vh] font-brand select-none hover:cursor-pointer" onClick={props.onClick}>
            <img src={SmallLogo} alt="logo" className={`h-[2em] ${props.invert ? 'invert' : ''}`} />
            <p className="flex gap-[.6vh] text-[1.5em] mt-[1%] font-extrabold">Blindtree<span className="font-normal text-[.6em] mt-1">Studios</span></p>
        </div>
    );
}

function NavbarItems(props: {onClick: (path: string) => void})
{
    const [{"dark-mode": darkMode}, setCookies] = useCookies(['dark-mode']);
    const state = darkMode === 'true'

    return (
        <div className="flex gap-[2em] items-center font-bold text-[.8em]">
            <p className="hover:cursor-pointer" onClick={() => {props.onClick("/games")}}>Games</p>
            <Switch 
                className="mt-[-10px]"
                size="sm"
                color={"red"} 
                checked={state} 
                onChange={(event) => setCookies("dark-mode", event.currentTarget.checked)} 
                onLabel={<p className="text-[1.5em]">{"🌙"}</p>}
                offLabel={<p className="text-[1.5em]">{"☀️"}</p>}
            />
        </div>
    );
}